// extracted by mini-css-extract-plugin
export const sliderSection = "product-slider-module--sliderSection--1XwiX";
export const sectionTitle = "product-slider-module--sectionTitle--nDHjU";
export const sliderContainer = "product-slider-module--sliderContainer--1qLh1";
export const productCard = "product-slider-module--productCard--3TTIs";
export const imageWrapper = "product-slider-module--imageWrapper--zrRyg";
export const image = "product-slider-module--image--3lNRz";
export const title = "product-slider-module--title--2V4kb";
export const footer = "product-slider-module--footer--2B5SM";
export const price = "product-slider-module--price--ZrUOZ";
export const button = "product-slider-module--button--nlLcS";