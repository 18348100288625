import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { ProductSlider } from "../../components/product-slider/product-slider"

export const IndexLatest = () => {
  const data = useStaticQuery(graphql`
    query LatestProductsQuery {
      allShopifyProduct(
        filter: {collections: {elemMatch: {shopifyId: {eq: "gid://shopify/Collection/635405271381"}}}}
        sort: {fields: createdAt, order: DESC}
        limit: 20
      ) {
        nodes {
          id
          title
          handle
          createdAt
          priceRangeV2 {
            minVariantPrice {
              amount
              currencyCode
            }
          }
          featuredImage {
            gatsbyImageData
          }
        }
      }
    }
  `)

  return (
    <ProductSlider 
      title="Neuheiten" 
      products={data.allShopifyProduct.nodes} 
    />
  )
}
