import * as React from "react"
import * as styles from './product-slider.module.scss'
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  autoplay: true,
  autoplaySpeed: 3000,
  pauseOnHover: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    }
  ]
}

const ProductCard = ({ product }) => (
  <div className={styles.productCard}>
    <div className={styles.imageWrapper}>
      {product.featuredImage && (
        <GatsbyImage
          image={product.featuredImage.gatsbyImageData}
          alt={product.title}
          className={styles.image}
        />
      )}
    </div>
    <h3 className={styles.title}>{product.title}</h3>
    <div className={styles.footer}>
      <p className={styles.price}>
        {parseFloat(product.priceRangeV2.minVariantPrice.amount).toLocaleString('de-DE', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })} €
      </p>
      <Link to={`/produkt/${product.handle}`} className={styles.button}>
        Mehr Details
      </Link>
    </div>
  </div>
)

export const ProductSlider = ({ title, products }) => {
  if (!products || products.length === 0) {
    return null
  }

  return (
    <div className={styles.sliderSection}>
      <h2 className={styles.sectionTitle}>{title}</h2>
      <div className={styles.sliderContainer}>
        <Slider {...sliderSettings}>
          {products.map(product => (
            <ProductCard key={product.id} product={product} />
          ))}
        </Slider>
      </div>
    </div>
  )
}
