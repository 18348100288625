import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { ProductSlider } from "../../components/product-slider/product-slider"

export const IndexBestsellers = () => {
  const data = useStaticQuery(graphql`
    query BestSellersQuery {
      allShopifyProduct(
        filter: {collections: {elemMatch: {shopifyId: {eq: "gid://shopify/Collection/635405304149"}}}}
        limit: 20
      ) {
        nodes {
          id
          title
          handle
          priceRangeV2 {
            minVariantPrice {
              amount
              currencyCode
            }
          }
          featuredImage {
            gatsbyImageData
          }
        }
      }
    }
  `)

  return (
    <ProductSlider 
      title="Am meisten verkauft" 
      products={data.allShopifyProduct.nodes} 
    />
  )
}
