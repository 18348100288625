import * as React from "react"
import * as styles from './index.module.scss';

import VideoBg from '../images/video-bg.mp4';

import Layout from "../page-components/layout/layout";

import loadable from '@loadable/component'
import { graphql, Link } from "gatsby";
import {IndexOverview} from "../page-components/index/index-overview";
import {IndexBanner} from "../page-components/index/index-banner";
import {IndexSlider} from "../page-components/index/index-slider";
import {IndexLogos} from "../page-components/index/index-logos";
import {IndexBestsellers} from "../page-components/index/index-bestsellers";
import {IndexLatest} from "../page-components/index/index-latest";
import {IndexSchnaeppchen} from "../page-components/index/index-schnaeppchen";
const LazyIndexLogoballCard = loadable( () => import('../page-components/cards/index-logoball-card.js') );

const IndexPage = ( { data }) => {
  return <Layout>

    <div className={styles.videoContainer + " "}>

      <video preload="metadata" loop muted autoPlay playsInline className={styles.video} >
        <source src={VideoBg} type="video/mp4"/>
      </video>


      <div className={styles.videoGradient} />

      <div className={styles.videoContent + " pb-5"}>
        <h1>Online-Shop für Golf</h1>
        <p>Golf-Schläger, individuell gedruckte Logo-Artikel, Golf Trolleys, ...</p>
      </div>

    </div>

    <div className="bg-light-gray">
      <div className="container">
        <div className="row pt-5">

          <IndexSlider />

          <IndexBanner />

          <IndexSchnaeppchen />

          <IndexBestsellers />
          
          <IndexLatest />

          <IndexLogos />

          <div className="py-3 py-lg-5">
            <div className="col-12">
              <h2>Von Profi Driver bis hin zu Damen Golfschuhen</h2>
              <p>
                Golfsport.at bietet Ihnen ein umfassendes Sortiment und liefert aus Österreich. Wir bieten den günstigsten Preis an.
                Bei individuell angedruckten Golfbällen unterstützen wir Sie mit unserer umfassenden Erfahrung.
                Damit Sie immer den perfekten Ball in Ihren Händen halten.
              </p>
            </div>
          </div>


        </div>
      </div>
    </div>

    <div className="container">
      <div className="row py-3 py-lg-5">
        <LazyIndexLogoballCard />
      </div>
    </div>


  </Layout>
}

export default IndexPage;

export const query = graphql`
    query IndexCategoriesQuery {                
          strapiMenu {
              elements {
                category {
                  id
                }
                id
                label
              }
          }
            
          allStrapiCategories {
              nodes {
                subCategories {
                  id
                  shopifyCollection {
                    title
                    handle
                    imageUrl
                  }
                }
                title
                id
                tag
                strapiId
                description                 
              }
          }
      }
`
