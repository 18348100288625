import React from "react";
import {useEffect, useState} from "react";
import {getStrapiUrl} from "../../helper/gsConstants";

export const IndexBanner = () => {

    const [ data, setData ] = useState(null);

    useEffect(() => {
        const action = async () => {
            const res = await fetch(`${getStrapiUrl()}/banner`);
            const json = await res.json();
            setData(json.banners);
        }

        action();
    }, []);

    if (!data) {
        return null;
    }

    return <div className={"my-5 tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 tw-grid-row tw-gap-3 lg:tw-gap-10 tw-auto-rows-auto"}>

        { data.map(
            d => {
                let css = "tw-w-full";
                if (d.size === "full") {
                    css = "lg:tw-col-span-2";
                }

                return <a href={d.url} className={css + " tw-cursor-pointer"}>
                        <img src={d.image.url} alt={d.alt} className={"w-100"} />
                </a>


            }
        )}

    </div>


}
